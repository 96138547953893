import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../pages/index.vue'
import Questionnaire from '../pages/questionnaire/index.vue'
import Result from '../pages/questionnaire/Result.vue'

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        name: 'Questionnaire',
        path: '/Questionnaire',
        component: Questionnaire,
    },
    {
        name: 'Result',
        path: '/Result',
        component: Result,
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router