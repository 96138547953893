<template>
  <div>
    <div class="container">
      <!-- 便便性格 -->
      <div class="poop-character-view">
        <div class="nike-name-view">
          您的昵称：
          <div class="nike-name">
            <input class="nike-name-input" v-model="nickName" maxlength="20" type="text" />
          </div>
        </div>
        <img class="character-image" :src="questionnaireResult.pages[0].imageUrl" />
        <div class="character-title-view">
          您的便便性格为
          <div class="character-title">{{ questionnaireResult.pages[0].title }}</div>
        </div>
        <div class="character-detail-view">
          {{ questionnaireResult.pages[0].description }}
        </div>
        <div class="share-button-view">
          <div class="share-button" @click="openShareImage">和朋友分享下</div>
        </div>
      </div>
      <!-- 便便时间 -->
      <div class="poop-time-view">
        <img class="poop-time-image" :src="questionnaireResult.pages[1].imageUrl" />
        <div class="poop-time-title">
          用时
          <div class="poop-time-num">{{ questionnaireResult.pages[1].self }}</div>
          个小时
        </div>
        <div class="poop-time-result-view">
          <div class="poop-time-result-title">{{ questionnaireResult.pages[1].title }}</div>
          <div class="poop-time-result-detail">
            {{ questionnaireResult.pages[1].description }}
          </div>
          <div class="average-time-view">
            <van-progress
              class="average-time-progress"
              :percentage="100"
              color="#ffffff"
              track-color="#00636f"
              stroke-width="8"
              :show-pivot="false"
            />
            中国平均时长：{{ questionnaireResult.pages[1].average }}
          </div>
          <div class="user-time-view">
            <van-progress
              class="user-time-progress"
              :percentage="percentTime"
              color="#8EE5EE"
              track-color="#00636f"
              stroke-width="8"
              :show-pivot="false"
            />
            您的运转时长：{{ questionnaireResult.pages[1].self }}
          </div>
        </div>
      </div>
      <!-- 健康提示 -->
      <div class="health-tips-view">
        <img class="health-tips-image" :src="questionnaireResult.pages[2].imageUrl" />
        <div class="health-tips-title">{{ questionnaireResult.pages[2].title }}</div>
        <div class="health-tips-detail">{{ questionnaireResult.pages[2].description }}</div>
      </div>
      <!-- 推荐商品 -->
      <div class="recommended-products-view" v-if="questionnaireResult.recommendProducts.length > 0">
        <div class="recommended-products-title">
          <div class="recommended-products-title-text">我们的产品助您拥有更健康的肠道</div>
          <img class="recommended-products-title-image" src="../../assets/images/questionnaire/jogging-bro.png" alt="" />
        </div>
        <div class="recommended-products-list">
          <!-- 跳转有赞H5商城 -->
          <!-- <div v-for="item in questionnaireResult.recommendProducts" :key="item.productId">
            <img
              class="recommended-product-cell"
              :src="item.originalImageUrl"
              @click="recommendedProudct(item, questionnaireResult.questionnaireResponseId)"
            />
          </div> -->
          <!-- 跳转小程序 -->
          <div v-for="item in questionnaireResult.recommendProducts" :key="item.productId">
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_f9f0aaf4a65b"
              :path="item.youzanUrl"
              @launch="weappLaunch($event, item.productId, questionnaireResult.questionnaireResponseId)"
              @error="weappError"
            >
              <div is="vue:script" type="text/wxtag-template">
                <img
                  :src="item.originalImageUrl"
                  style="width: 22rem; margin-bottom: 1rem; background-color: #ffffff; border-radius: 1rem"
                />
              </div>
            </wx-open-launch-weapp>
          </div>
        </div>
      </div>
      <div class="pu-pu-pu-view">
        <img class="pu-pu-pu-logo" src="../../assets/images/questionnaire/pu-pu-pu.png" />
      </div>
    </div>
    <!-- 分享图弹框 -->
    <van-overlay :show="showImage" @click="closeShareImage">
      <div class="share-image-view" @click.stop>
        <div class="close-popup-view">
          <div class="close-popup-button" @click="closeShareImage">
            关闭窗口
            <img class="close-popup-icon" src="../../assets/images/questionnaire/close.png" alt="" />
          </div>
        </div>
        <img class="share-image" :src="posterUrl" alt="" />
        <div class="share-button-view">
          <div class="share-button">分享</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template> 

<script>
import { ref, getCurrentInstance } from 'vue';
import { Toast } from 'vant';
import wx from 'weixin-js-sdk';

export default {
  name: 'PageIndex',
  setup() {
    const porxy = getCurrentInstance();
    let questionnaireResult = ref('');
    let percentTime = 0;
    let showImage = ref(false);
    let nickName = '';
    let posterUrl = '';

    function openShareImage() {
      if (this.nickName) {
        porxy.appContext.config.globalProperties
          .get(porxy.appContext.config.globalProperties.apiCode.getPoster, {
            questionnaireResponseId: this.questionnaireResult.questionnaireResponseId,
            nickName: this.nickName,
          })
          .then((res) => {
            if (res.code === 0) {
              wx.previewImage({
                current: res.data.posterUrl, // 当前显示图片的http链接
                urls: [res.data.posterUrl], // 需要预览的图片http链接列表
              });
            } else {
              Toast(res.msg);
            }
          });
      } else {
        Toast('请先输入昵称');
      }
    }

    function closeShareImage() {
      this.showImage = false;
    }

    function recommendedProudct(item, questionnaireResponseId) {
      porxy.appContext.config.globalProperties
        .post(porxy.appContext.config.globalProperties.apiCode.recordProduct, {
          questionnaireResponseId: questionnaireResponseId,
          productId: item.productId,
        })
        .then(() => {
          if (item.youzanUrl) {
            window.location.href = item.youzanUrl;
          } else {
            Toast('找不到有效链接');
          }
        });
    }

    function weappLaunch(e, productId, questionnaireResponseId) {
      console.log(e);
      // 记录点击商品
      porxy.appContext.config.globalProperties.post(porxy.appContext.config.globalProperties.apiCode.recordProduct, {
        questionnaireResponseId: questionnaireResponseId,
        productId: productId,
      });
    }

    function weappError(e) {
      console.log(e);
    }

    return {
      questionnaireResult,
      percentTime,
      posterUrl,
      showImage,
      nickName,
      recommendedProudct,
      openShareImage,
      closeShareImage,
      weappLaunch,
      weappError,
    };
  },
  mounted() {
    this.questionnaireResult = JSON.parse(localStorage.getItem('questionnaire-result'));
    const { self, average } = this.questionnaireResult.pages[1];
    const percent = (self / average) * 100;
    this.percentTime = percent < 100 ? percent : 100;
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00636f;
  color: #ffffff;
}
/* 便便性格 */
.poop-character-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem #ffffff dashed;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.nike-name-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
.nike-name {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.1rem #ffffff solid;
}
.nike-name-input {
  display: flex;
  border: none;
  outline: none;
  height: 1rem;
  width: 6rem;
  color: #ffffff;
  background-color: #00636f;
}
.character-image {
  height: 12rem;
  width: 12rem;
}
.character-title-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.character-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 3rem;
  font-weight: bold;
}
.character-detail-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.share-button-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
}
.share-button {
  width: 60%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00636f;
  background-color: #ffffff;
  border-radius: 0.5rem;
  font-weight: bold;
}
/* 便便时间 */
.poop-time-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem #ffffff dashed;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.poop-time-image {
  height: 15rem;
  width: 10rem;
}
.poop-time-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.poop-time-num {
  font-size: 2rem;
}
.poop-time-result-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poop-time-result-title {
  height: 2rem;
  font-weight: bold;
}
.average-time-view {
  width: 100%;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-top: 1rem;
}
.average-time-progress {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
}
.user-time-view {
  width: 100%;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.user-time-progress {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
}
/* 健康提示 */
.health-tips-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem #ffffff dashed;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.health-tips-image {
  margin: 1rem;
  height: 10rem;
  width: 10rem;
}
.health-tips-title {
  height: 2rem;
  font-weight: bold;
}
.health-tips-detail {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
/* 推荐商品 */
.recommended-products-view {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recommended-products-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.recommended-products-title-text {
  font-weight: bold;
}
.recommended-products-title-image {
  height: 8rem;
  width: 8rem;
}
.recommended-products-list {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recommended-product-cell {
  width: 22rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius: 1rem;
}

/* logo */
.pu-pu-pu-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 100%;
}
.pu-pu-pu-logo {
  height: 3rem;
  width: 7rem;
}
/* 分享图弹框 */
.share-image-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.close-popup-view {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.close-popup-button {
  height: 3rem;
  width: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.close-popup-icon {
  height: 1rem;
  width: 1rem;
}
.share-image {
  height: 33rem;
  width: 85%;
}
.share-button-view {
  width: 90%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-button {
  height: 3rem;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #00636f;
  background-color: #ffffff;
  border-radius: 0.8rem;
}
</style>
