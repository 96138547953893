<template>
  <div>
    <div class="container" v-if="isWeChatBrower">
      <div class="content-view">
        <img class="questionnaire-image" :src="questionnaireData.imageUrl" />
      </div>
      <div class="bottom-action-view">
        <div class="action-button" @click="start">开始测试</div>
      </div>
    </div>
    <div class="container" v-else>请在微信中打开！</div>
  </div>
</template> 

<script>
import { isWeChat, getQueryString } from '@/utils/util';
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { Toast } from 'vant';

export default {
  name: 'PageIndex',
  setup() {
    let questionnaireData = ref('');
    let isWeChatBrower = isWeChat();
    const router = useRouter();

    function start() {
      router.push('/Questionnaire');
    }

    function getCode() {
      const redirect_uri = encodeURIComponent(window.location.href);
      const linkUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wxAppId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      window.location.href = linkUrl;
    }

    function wxLogin(code) {
      this.get(this.apiCode.loginMpWebSite, { code: code, appId: this.wxAppId }).then((res) => {
        if (res.code === 0) {
          // 保存token
          localStorage.setItem('token', res.data.token);
          // 获取问卷id
          let questionnaireId = getQueryString('questionnaireId');
          questionnaireId = questionnaireId && questionnaireId.replace('#', '').replace('/', '');
          localStorage.setItem('questionnaireId', questionnaireId);
          // 获取问卷数据
          this.get(this.apiCode.questionnaireGet, { id: questionnaireId }).then((res) => {
            this.questionnaireData = res.data;
            localStorage.setItem('questionnaire-data', JSON.stringify(res.data));
          });
          // 保存原始链接
          if (questionnaireId) {
            localStorage.setItem('sourceLink', window.location.href.split('&')[0]);
          } else {
            localStorage.setItem('sourceLink', window.location.href.split('?')[0]);
          }
        } else {
          Toast(res.msg);
        }
      });
    }

    return {
      questionnaireData,
      isWeChatBrower,
      start,
      getCode,
      wxLogin,
    };
  },
  mounted() {
    if (this.isWeChatBrower) {
      const token = localStorage.getItem('token');
      if (!token) {
        const code = getQueryString('code');
        if (code) {
          this.wxLogin(code);
        } else {
          this.getCode();
        }
      } else {
        // 获取问卷id
        let questionnaireId = getQueryString('questionnaireId');
        questionnaireId = questionnaireId && questionnaireId.replace('#', '').replace('/', '');
        localStorage.setItem('questionnaireId', questionnaireId);
        // 获取问卷数据
        this.get(this.apiCode.questionnaireGet, { id: questionnaireId }).then((res) => {
          this.questionnaireData = res.data;
          localStorage.setItem('questionnaire-data', JSON.stringify(res.data));
        });
      }
    } else {
      // 其他浏览器
      this.isWeChatBrower = false;
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d8f2f5;
}

.content-view {
  width: 90%;
  display: flex;
  flex: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questionnaire-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
}

/* 底部操作 */
.bottom-action-view {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 90%;
  font-weight: bold;
  color: #00636f;
  background-color: #ffffff;
  border-radius: 0.8rem;
}
</style>
