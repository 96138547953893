<template>
  <div class="container">
    <div class="top-indicator-view">{{ currentIndex + 1 }}/{{ questionnaireData.pages.length }}</div>
    <!-- 题目 -->
    <div class="subject-card-view" v-if="currentQuestionnaire.isQuestion === 'Y'">
      <div class="subject-view">
        <div class="subject-title-view">{{ currentQuestionnaire.title }}</div>
        <div class="subject-detail-view">
          <div class="subject-detail-image" v-if="currentQuestionnaire.description">
            <img class="green-mark-image" src="../../assets/images/questionnaire/green-mark.png" />
          </div>
          <div class="subject-detail-text">{{ currentQuestionnaire.description }}</div>
        </div>
      </div>
      <div class="subject-action-view">
        <MyInput
          :key="currentQuestionnaire.id"
          v-if="currentQuestionnaire.field.tag === 'INPUT'"
          :title="currentQuestionnaire.field.label"
          :name="currentQuestionnaire.field.name"
          :prefix="currentQuestionnaire.field.prefix"
          :unit="currentQuestionnaire.field.suffix"
          :type="currentQuestionnaire.field.type"
          :min="0"
          @inputChanged="onInput"
        ></MyInput>
        <MyRadio
          :key="currentQuestionnaire.id"
          v-if="currentQuestionnaire.field.tag === 'RADIO'"
          :name="currentQuestionnaire.field.name"
          :options="currentQuestionnaire.field.options"
          @radioChanged="onRadio"
        ></MyRadio>
        <MyCheckbox
          :key="currentQuestionnaire.id"
          v-if="currentQuestionnaire.field.tag === 'CHECK_BOX'"
          :name="currentQuestionnaire.field.name"
          :options="currentQuestionnaire.field.options"
          @checkBoxChanged="onCheckbox"
        ></MyCheckbox>
      </div>
    </div>
    <!-- 插图 -->
    <div class="questionnaire-image-view" v-else>
      <img class="questionnaire-image" :src="currentQuestionnaire.imageUrl" />
    </div>
    <!-- 底部操作 -->
    <div class="bottom-action-view">
      <div class="action-button" v-if="currentIndex < questionnaireData.pages.length - 1" @click="nextQuestion">
        {{ currentQuestionnaire.isQuestion === 'Y' ? '下一题' : '了解' }}
      </div>
      <div class="submit-action-button" v-else @click="submit">提交</div>
    </div>
    <div class="bottom-blank"></div>
  </div>
</template> 

<script>
import MyInput from '../../components/MyInput.vue';
import MyRadio from '../../components/MyRadio.vue';
import MyCheckbox from '../../components/MyCheckbox.vue';
import { ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { Toast } from 'vant';

export default {
  name: 'PageIndex',
  setup() {
    const porxy = getCurrentInstance();
    const router = useRouter();
    const questionnaireData = JSON.parse(localStorage.getItem('questionnaire-data')); // 所有题目
    let currentIndex = ref(0); // 当前题目下标
    let currentQuestionnaire = ref(''); // 当前题目
    let currentKey = ''; // 当前作答键
    let currentValue = ''; // 当前作答值
    let isAnswered = false; // 是否已作答
    let answer = {}; // 最终答案

    // 下一题
    function nextQuestion() {
      if (this.currentQuestionnaire.isQuestion !== 'Y' || isAnswered) {
        // 组装答案
        this.answer[currentKey] = currentValue;
        // 下一题
        if (this.currentIndex < this.questionnaireData.pages.length - 1) {
          this.currentIndex++;
          this.currentQuestionnaire = this.questionnaireData.pages[this.currentIndex];
          isAnswered = false;
        }
      } else {
        Toast('请先作答');
      }
    }

    // 提交
    function submit() {
      if (this.currentQuestionnaire.isQuestion !== 'Y' || isAnswered) {
        this.answer[currentKey] = currentValue;
        this.answer['questionnaireId'] = localStorage.getItem('questionnaireId');
        porxy.appContext.config.globalProperties
          .post(porxy.appContext.config.globalProperties.apiCode.questionnaireSubmit, this.answer)
          .then((res) => {
            if (res.code === 0) {
              localStorage.setItem('questionnaire-result', JSON.stringify(res.data));
              router.push('/Result');
            } else {
              Toast(res.msg);
            }
          });
      } else {
        Toast('请先作答');
      }
    }

    function onInput(value) {
      currentKey = value[0];
      currentValue = value[1];
      if (currentValue) {
        isAnswered = true;
      } else {
        isAnswered = false;
      }
    }
    function onRadio(value) {
      currentKey = value[0];
      currentValue = value[1];
      if (value) {
        isAnswered = true;
      } else {
        isAnswered = false;
      }
    }
    function onCheckbox(value) {
      currentKey = value[0];
      currentValue = value[1];
      if (value) {
        isAnswered = true;
      } else {
        isAnswered = false;
      }
    }
    return {
      currentIndex,
      questionnaireData,
      currentQuestionnaire,
      currentKey,
      currentValue,
      answer,
      nextQuestion,
      submit,
      onInput,
      onRadio,
      onCheckbox,
    };
  },
  components: {
    MyInput,
    MyRadio,
    MyCheckbox,
  },
  mounted() {
    this.currentQuestionnaire = this.questionnaireData.pages[this.currentIndex];
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 顶部指示器 */
.top-indicator-view {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

/* 题目 */
.subject-card-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.3rem rgba(0, 0, 255, 0.3);
  color: #34393f;
}

.subject-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #d8f2f5;
  border-radius: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.subject-title-view {
  width: 90%;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 0.3rem;
  color: #00636f;
  margin-bottom: 1rem;
}

.subject-detail-view {
  width: 90%;
  display: flex;
  flex-direction: row;
}
.subject-detail-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 5rem;
}
.subject-detail-text {
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1rem;
}

.green-mark-image {
  height: 1rem;
  width: 1rem;
}

.subject-action-view {
  width: 90%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/* 插图 */
.questionnaire-image-view {
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionnaire-image {
  height: 35rem;
  width: 21rem;
}
/* 底部操作 */
.bottom-action-view {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background-color: #ffffff;
}
.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 90%;
  font-weight: bold;
  color: #ffffff;
  background-color: #744d23;
  border-radius: 0.8rem;
}
.submit-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 90%;
  font-weight: bold;
  color: #ffffff;
  background-color: #eeb033;
  border-radius: 0.8rem;
}
.bottom-blank {
  height: 6rem;
}
</style>
