/**
 * 获取url中的参数
 * @param {String} name 参数名
 * @param {url} name 指定url，若不传则自动获取
 */
export const getQueryString = function (name, url) {
	url = url || window.location.href
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	let index = url.indexOf('?')
	let searchStr = url.substring(index, url.length)
	var r = searchStr.substr(1).match(reg);
	if (r != null) return unescape(r[2]);
	return null;
}

/**
 * 判断是否为微信浏览器
 */
export const isWeChat = () => {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		return true;
	} else {
		return false;
	}
}

/**
 * 随机字母数字字符串生成算法
 * @param {*} randomFlag 是否任意长度
 * @param {*} min  min-任意长度最小位[固定位数]
 * @param {*} max  max-任意长度最大位
 */
export function randomWord(randomFlag, min, max) {
	var str = "",
		range = min,
		arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
			'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F',
			'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
		];
	// 随机产生
	if (randomFlag) {
		range = Math.round(Math.random() * (max - min)) + min;
	}
	for (var i = 0; i < range; i++) {
		let pos = Math.round(Math.random() * (arr.length - 1));
		str += arr[pos];
	}
	return str;
}

/**
 * 根据需要的样式格式化日期时间
 * @param {String} time 指定时间戳
 * @param {String} format 需要的格式
 */
export function formatDate(time, format) {
	const t = new Date(time);
	const tf = function (i) {
		return (i < 10 ? '0' : '') + i;
	};
	return format.replace(/yyyy|MM|dd|HH|mm|ss/g, a => {
		switch (a) {
			case 'yyyy':
				return tf(t.getFullYear());
			case 'MM':
				return tf(t.getMonth() + 1);
			case 'mm':
				return tf(t.getMinutes());
			case 'dd':
				return tf(t.getDate());
			case 'HH':
				return tf(t.getHours());
			case 'ss':
				return tf(t.getSeconds());
			default:
				return '';
		}
	});
}

/**
 * 关键信息隐藏
 * @param str 字符串
 * @param frontLen 字符串前面保留位数
 * @param endLen 字符串后面保留位数
 * @returns {string} 脱敏后的字符串
 */
export function hideCode(str = '', frontLen, endLen) {
	var len = str.length - frontLen - endLen;
	var xing = '';
	for (var i = 0; i < len; i++) {
		xing += '*';
	}
	return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
}
