<template>
  <div class="container">
    <div class="title-view">{{ title }}</div>
    <div class="input-view">
      <div class="prefix-view" v-if="prefix">{{ prefix }}</div>
      <input
        class="my-input"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :min="min"
        :max="max"
        @input="inputChanged($event)"
      />
      <div class="input-unit">{{ unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      require: true,
      default: '名称',
    },
    name: {
      type: String,
      require: true,
      default: '',
    },
    prefix: {
      type: String,
      require: false,
      default: '',
    },
    unit: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    type: {
      type: String,
      require: false,
      default: 'text',
    },
    min: {
      type: Number,
      require: false,
      default: 0,
    },
    max: {
      type: Number,
      require: false,
    },
  },
  methods: {
    inputChanged($event) {
      if ($event.target.value.length > 3) $event.target.value = $event.target.value.slice(0, 3);
      this.$emit('inputChanged', [this.name, $event.target.value]);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-view {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
}

.input-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid #00636f;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
}

.prefix-view {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00636f;
  font-weight: bold;
  width: 3rem;
}

.my-input {
  display: flex;
  border: none;
  outline: none;
  height: 2rem;
  width: 10rem;
  font-size: 1rem;
  font-weight: bold;
}

.input-unit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00636f;
  font-weight: bold;
  width: 5rem;
}
</style>
