<template>
  <router-view></router-view>
</template>

<script>
import wxconfig from './utils/WeChatConfig';
export default {
  name: 'App',
  mounted() {
    wxconfig();
  },
};
</script>

<style>
body {
  background-color: rgba(229, 229, 229, 0.3);
  margin: 0;
  padding: 0;
}
</style>
