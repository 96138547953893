import axios from 'axios';
import {
  randomWord
} from './util'
import {
  MD5
} from './md5'
import {
  Toast, Dialog
} from 'vant';


const apiCode = {
  getJsapiSignature: 'wxapi/getJsapiSignature', // 获取sdk凭证
  loginMpWebSite: 'wxapi/party/loginMpWebSite', // 微信登陆
  questionnaireGet: "wxapi/questionnaire/get", // 问卷数据获取
  questionnaireSubmit: "wxapi/questionnaire/submit", // 问卷提交
  getPoster: "wxapi/questionnaire/getPoster", // 获取分享海报
  recordProduct: "wxapi/questionnaire/recordProduct", // 记录商品点击
}


// 环境
let baseURL = ""
let shareURL = ""
if (process.env.NODE_ENV == 'production') {
  baseURL = 'https://fries.adfontes.com.cn/';
  // baseURL = 'https://test-fries.adfontes.com.cn/';
} else {
  baseURL = 'https://test-fries.adfontes.com.cn/';
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "apiAccountId": "APPLEPIE",
    "content-type": "multipart/form-data",
    // "content-type": "application/x-www-form-urlencoded",
  },
});

// 请求拦截处理
instance.interceptors.request.use(
  config => {
    // 获取token
    config.headers.token = localStorage.getItem('token') ? localStorage.getItem('token') : ''
    // 获取时间戳
    const timestamp = Date.now()
    config.headers.tranTimestamp = timestamp
    config.headers.tranId = config.headers.apiAccountId + timestamp + randomWord(false, 3)
    // 签名
    config.headers.sign = sign(config.headers, config.method === 'get' ? config.params : config.data)
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

// 返回拦截处理
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.code === '500100') {
        Dialog.alert({
          title: '温馨提示',
          message:
            '当前登录状态已失效,将重新登录',
          confirmButtonColor: '#744d23'
        })
          .then(() => {
            localStorage.removeItem('token')
            window.location.href = localStorage.getItem('sourceLink') || window.location.href;
          })
        // localStorage.removeItem('token')
        // if (!localStorage.getItem('token')) {
        //   window.location.href = localStorage.getItem('sourceLink') || window.location.href;
        // }
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // 处理网络请求失败
    if (error.response) {
      switch (error.response.status) {
        case 404:
          Toast('页面丢啦');
          break;
        case 401:
          Toast('token失效');
          break;
        case 500:
          Toast('服务器故障');
          break;
        case 503:
          Toast('服务器繁忙');
          break;
        default:
          Toast('系统繁忙');
      }
    } else {
      Toast('系统繁忙');
    }
    Promise.reject(error);
  },
);

const get = (url, params, showLoading = true) => {
  if (showLoading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
  }
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params
      })
      .then(response => {
        if (showLoading) {
          Toast.clear();
        }
        resolve(response.data);
      })
      .catch(error => {
        if (showLoading) {
          Toast.clear();
        }
        reject(error);
      });
  });
}


const post = (url, params, showLoading = true) => {
  if (showLoading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
  }
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then(response => {
        if (showLoading) {
          Toast.clear();
        }
        resolve(response.data);
      })
      .catch(error => {
        if (showLoading) {
          Toast.clear();
        }
        reject(error);
      });
  });
}


const upload = (url, params, file, showLoading = true) => {
  if (showLoading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
  }
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    for (const key in params) {
      if (key !== 'uri') {
        formData.append(key, params[key]);
      }
    }
    formData.append('file', file);
    instance
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (showLoading) {
          Toast.clear();
        }
        resolve(response.data);
      })
      .catch(error => {
        if (showLoading) {
          Toast.clear();
        }
        reject(error);
      });
  });
}

/**
 * 基础请求封装
 */
//  const request = (config, successCallback, failCallback, completeCallback) => {
// 	// 追踪码设置
// 	config.header.trackingCodeId = uni.getStorageSync('channel') || "000000000"
// 	// token获取
// 	const token = uni.getStorageSync("token")
// 	config.header.token = token
// 	// 获取时间戳
// 	const timestamp = Date.now()
// 	config.header.tranTimestamp = timestamp
// 	config.header.tranId = config.header.apiAccountId + timestamp + randomWord(false, 3)
// 	// 签名
// 	config.header.sign = sign(config.header, config.data)
// 	uni.request({
// 		url: config.url,
// 		data: config.data,
// 		method: config.method,
// 		header: config.header,
// 		timeout: config.timeout,
// 		success: successCallback,
// 		fail: failCallback,
// 		complete: completeCallback,
// 	})
// }

/**
 * 签名函数
 */
const sign = (header, data) => {
  let signArr = []
  // 取出固定参数
  const {
    apiAccountId,
    tranId,
    tranTimestamp,
    token
  } = header
  // 组装签名对象
  if (data) {
    for (let key in data) {
      signArr.push(data[key])
    }
  }
  signArr.push(apiAccountId)
  signArr.push('4vz7RmbzjHFQPP1E')
  signArr.push(tranId)
  signArr.push(tranTimestamp)
  signArr.push(token)
  // 使用ASCII排序
  let signStr = signArr.sort().join('')
  // 处理表情等特殊字符，需提前编码后MD5
  return MD5(fixedEncodeURIComponent(signStr)).toUpperCase()
}

// 无法编码的几个特殊字符处理[!'()*]
function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}


export {
  get,
  post,
  upload,
  apiCode,
  shareURL
}