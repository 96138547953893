<template>
  <div class="container">
    <div class="title-view">{{ title }}（多选）</div>
    <div class="check-box-view" v-for="item in options" :key="item">
      <div
        :class="[selectedList.indexOf(item.value) > -1 ? 'check-box-option-active' : 'check-box-option']"
        @click="handleClick(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyCheckbox',
  data() {
    return {
      selectedList: [],
    };
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '请选择',
    },
    name: {
      type: String,
      require: true,
      default: '',
    },
    options: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    handleClick(value) {
      this.select(value, this.selectedList);
      this.$emit('checkBoxChanged', [this.name, this.selectedList.join(',')]);
    },
    // 多选操作算法
    select(value, array = []) {
      if (value) {
        let num = array.indexOf(value);
        if (num === -1) {
          array.push(value);
        } else {
          array.splice(num, 1);
        }
      }
      return array;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-view {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
}

.check-box-view {
  width: 100%;
}

.check-box-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  color: #00636f;
  background-color: #dbe9eb;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

.check-box-option-active {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  color: #ffffff;
  background-color: #00636f;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}
</style>
