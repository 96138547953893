import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { get, post, apiCode } from './utils/Axios'
import Vant from 'vant';
import 'vant/lib/index.css';


const app = createApp(App)

app.config.globalProperties.wxAppId = 'wxcb2a76c1fea86748'
app.config.globalProperties.get = get
app.config.globalProperties.post = post
app.config.globalProperties.apiCode = apiCode

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('wx-open-launch-weapp')

app.use(router)
app.use(Vant)
app.mount('#app')
