<template>
  <div class="container">
    <div class="title-view">{{ title }}（单选）</div>
    <div class="radio-view" v-for="item in options" :key="item">
      <div :class="[selectedValue === item.value ? 'radio-option-active' : 'radio-option']" @click="handleClick(item.value)">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyRadio',
  data() {
    return {
      selectedValue: '',
    };
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '请选择',
    },
    name: {
      type: String,
      require: true,
      default: '',
    },
    options: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    handleClick(vlaue) {
      this.selectedValue = vlaue;
      this.$emit('radioChanged', [this.name, vlaue]);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-view {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
}

.radio-view {
  width: 100%;
}

.radio-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  color: #00636f;
  background-color: #dbe9eb;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}

.radio-option-active {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  color: #ffffff;
  background-color: #00636f;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}
</style>
