import wx from 'weixin-js-sdk'
import {
    get,
    apiCode,
} from './Axios'

function wxconfig() {
    const url = (window.location.href.split('#')[0]);
    get(apiCode.getJsapiSignature, { url: url, appId: 'wxcb2a76c1fea86748' }).then((res) => {
        if (res.code === 0) {
            const { appId, nonceStr, signature, timestamp } = res.data
            wx.config({
                appId: appId,
                nonceStr: nonceStr,
                signature: signature,
                timestamp: timestamp,
                debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                jsApiList: ['previewImage'],  // 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-weapp']
            });
        } else {
            console.log('微信凭证获取失败')
        }
    });
}

export default wxconfig;